import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/help-layout.tsx";
import Step1Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step1-converted.mp4";
import Step2Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step2-converted.mp4";
import Step3Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step3-converted.mp4";
import Step4Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step4-converted.mp4";
import Step5Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step5-converted.mp4";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const YouTube = makeShortcode("YouTube");
const VideoLoop = makeShortcode("VideoLoop");
const Message = makeShortcode("Message");
const Location = makeShortcode("Location");
const IfVisitedTag = makeShortcode("IfVisitedTag");
const IfTag = makeShortcode("IfTag");
const EndIfTag = makeShortcode("EndIfTag");
const AddCommandTag = makeShortcode("AddCommandTag");
const ShowCommandTag = makeShortcode("ShowCommandTag");
const SetGlobalTag = makeShortcode("SetGlobalTag");
const Global = makeShortcode("Global");
const ShowExitTag = makeShortcode("ShowExitTag");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Note: This tutorial assumes you have knowledge from the `}<a parentName="p" {...{
        "href": "/help/getting-started"
      }}>{`Getting Started`}</a>{` tutorial. If you haven't done so yet, we recommend you read and complete it before continuing.`}</p>
    <h1>{`Locations and Exits`}</h1>
    <Box align="center" mb="20px" mdxType="Box">
  <YouTube videoId="fMO7Pob4J6g" mdxType="YouTube" />
    </Box>
    <p>{`Locations are another way to add interactivity to a story by giving it a sense of place.`}</p>
    <p>{`The player's character can only be in a single location at any time and any time a player enters a new location, that location's description is written to the screen.`}</p>
    <h3>{`Step 1. Create a location based story`}</h3>
    <VideoLoop width="640px" source={Step1Video} mdxType="VideoLoop" />
    <blockquote>
      <p parentName="blockquote">{`Create a new story named `}<inlineCode parentName="p">{`Spooky Tutorial`}</inlineCode>{`. Under `}<inlineCode parentName="p">{`Start Options`}</inlineCode>{` select `}<inlineCode parentName="p">{`Location`}</inlineCode>{`.  Name your starting location `}<inlineCode parentName="p">{`DOWNSTAIRS`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Story`}</strong>{`.`}</p>
    </blockquote>
    <p>{`Instead of running the `}<Message mdxType="Message">{`INIT`}</Message>{` message, the Location start option starts the player in a specified location. When the story begins, the location's text will print to the screen.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Select your new `}<Location mdxType="Location">{`DOWNSTAIRS`}</Location>{` location on the left. Give it a title of `}<inlineCode parentName="p">{`Downstairs`}</inlineCode>{` and in the text area type in something like `}<inlineCode parentName="p">{`Dust and cobwebs cover the furniture. There are stairs here`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Location`}</strong>{` and `}<strong parentName="p">{`Test Story`}</strong>{`. You should see the location's title at the top and the text should print to the screen.`}</p>
    </blockquote>
    <h3>{`Step 2. Add another location and some exits`}</h3>
    <VideoLoop width="640px" source={Step2Video} mdxType="VideoLoop" />
    <p>{`Exits let the player move from one location to another. Every time the player enters a new location, the location's text will run and the results will print to the screen.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit the `}<Location mdxType="Location">{`DOWNSTAIRS`}</Location>{` location and click `}<strong parentName="p">{`Add Exit`}</strong>{` at the bottom. Type `}<inlineCode parentName="p">{`Go upstairs`}</inlineCode>{` as the exit's text. `}<br />{`Select `}<inlineCode parentName="p">{`New Location...`}</inlineCode>{` and name it `}<inlineCode parentName="p">{`UPSTAIRS`}</inlineCode>{`. Leave `}<inlineCode parentName="p">{`Visible By Default`}</inlineCode>{` on. Edit the `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` location by clicking it on the left. Name it `}<inlineCode parentName="p">{`Upstairs`}</inlineCode>{`, and add the text `}<inlineCode parentName="p">{`It is so dark you can barely see.`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Location`}</strong>{` and `}<strong parentName="p">{`Test Story`}</strong>{` and test your exit.`}</p>
    </blockquote>
    <p>{`Exits are one-way. You have to make both sides of the exit if you want to go back and forth.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{`. Follow the previous steps to add an exit from `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` back to `}<Location mdxType="Location">{`DOWNSTAIRS`}</Location>{`. Click `}<strong parentName="p">{`Test Story`}</strong>{`. You can now go back and forth between locations.`}</p>
    </blockquote>
    <h3>{`Step 3. Use the If Visited tag`}</h3>
    <VideoLoop width="640px" source={Step3Video} mdxType="VideoLoop" />
    <p>{`Location text is good for descriptions of where the player just entered, but sometimes you only want text to appear the first time the player enters a location. `}</p>
    <p>{`Wrdie keeps track of the locations the player has visited and allows you to check them with the `}<IfVisitedTag mdxType="IfVisitedTag" />{` tag. Just like `}<IfTag mdxType="IfTag" />{` tags, an `}<IfVisitedTag mdxType="IfVisitedTag" />{` tag must be closed with an `}<EndIfTag mdxType="EndIfTag" />{` or else the location will not run.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Drag an `}<IfVisitedTag mdxType="IfVisitedTag" />{` and an `}<EndIfTag mdxType="EndIfTag" />{` tag into the text for `}<Location mdxType="Location">{`DOWNSTAIRS`}</Location>{`. Edit the tag and set its condition to `}<inlineCode parentName="p">{`if not visited`}</inlineCode>{` and the location name to `}<inlineCode parentName="p">{`DOWNSTAIRS`}</inlineCode>{`. Add some exposition between the tags, something like `}<inlineCode parentName="p">{`You aren't sure why you took the dare, but now that you're in here you'll have to find that upstairs window or else the other kids will all think you're chicken.`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Location`}</strong>{` and `}<strong parentName="p">{`Test Story`}</strong>{`. Your text should only appear when the story starts and not reappear when you re-enter `}<Location mdxType="Location">{`DOWNSTAIRS`}</Location>{`.`}</p>
    </blockquote>
    <p>{`Now let's use an `}<IfVisitedTag mdxType="IfVisitedTag" />{` to make things a little spookier.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click on the `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` location to edit it. Add an `}<IfVisitedTag mdxType="IfVisitedTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{` to the text. In between write `}<inlineCode parentName="p">{`A shadowy figure stands before you. She points to an antique sconce on the wall.`}</inlineCode>{` Set the tag's condition to `}<inlineCode parentName="p">{`if visited`}</inlineCode>{` and the location to `}<inlineCode parentName="p">{`UPSTAIRS`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Location`}</strong>{` and `}<strong parentName="p">{`Test Story`}</strong>{`. The ghost should only appear the second time you go upstairs. `}</p>
    </blockquote>
    <h3>{`Step 4. Location specific commands and visibility`}</h3>
    <VideoLoop width="640px" source={Step4Video} mdxType="VideoLoop" />
    <p>{`You can add commands to location based stories just like we saw in the `}<a parentName="p" {...{
        "href": "/help/getting-started"
      }}>{`Getting Started`}</a>{` tutorial by using the `}<AddCommandTag mdxType="AddCommandTag" />{` tag. `}</p>
    <p>{`However, sometimes a command should only appear when the user is in one specific location. An example would be a switch on the wall that you can toggle or a key on a table for the player to take.`}</p>
    <p>{`For these, you can use a Location Specific Command.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` and click `}<strong parentName="p">{`Add Command`}</strong>{` at the bottom. Make the text `}<inlineCode parentName="p">{`Pull the sconce`}</inlineCode>{` and create a new message named `}<inlineCode parentName="p">{`PULL-SCONCE`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Location`}</strong>{` and then edit your new `}<Message mdxType="Message">{`PULL-SCONCE`}</Message>{` message by clicking it on the left. Write `}<inlineCode parentName="p">{`There is a loud creaking noise behind you. When you look back, the shadowy figure is gone`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Message`}</strong>{` and `}<strong parentName="p">{`Test Story`}</strong>{`. Your new command should appear only when you are upstairs. `}</p>
    </blockquote>
    <p>{`Sometimes a command or exit should only appear under certain circumstances. For this you can control their visibility. `}</p>
    <p>{`Let's change the visibility of our new command so that it shows up only after the ghost appears. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` and toggle `}<inlineCode parentName="p">{`Visible by default`}</inlineCode>{` to `}<inlineCode parentName="p">{`off`}</inlineCode>{` for the `}<inlineCode parentName="p">{`Pull the sconce`}</inlineCode>{` location command. Now drag a `}<ShowCommandTag mdxType="ShowCommandTag" />{` tag in between the `}<IfVisitedTag mdxType="IfVisitedTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{` tags and set the command to `}<inlineCode parentName="p">{`Pull the sconce`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Location`}</strong>{` and `}<strong parentName="p">{`Test Story`}</strong>{`. The command to pull the sconce should only appear after the ghost tells you about it.  `}</p>
    </blockquote>
    <h3>{`Step 5. Use a post run trigger`}</h3>
    <VideoLoop width="640px" source={Step5Video} mdxType="VideoLoop" />
    <p>{`You've probably noticed that locations also have a text area called the `}<inlineCode parentName="p">{`Post Run Trigger`}</inlineCode>{`. While the location text runs when the player enters the location, the post run trigger runs both after entering the location AND after any command that runs while the player is in the location.`}</p>
    <p>{`If something in the location needs to change after a command runs, you need to use `}<inlineCode parentName="p">{`Post Run Trigger`}</inlineCode>{`. One example is showing a hidden door after a user presses a button (or pulls on a sconce).`}</p>
    <p>{`We'll do this in a few steps. First we need to make the new location and exit.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` and add click `}<strong parentName="p">{`Add Exit`}</strong>{`. Make the text `}<inlineCode parentName="p">{`Open the door`}</inlineCode>{` and create a new location called `}<Location mdxType="Location">{`BEDROOM`}</Location>{`. Set `}<inlineCode parentName="p">{`Visible by default`}</inlineCode>{` to `}<inlineCode parentName="p">{`off`}</inlineCode>{`. Add some nice text like `}<inlineCode parentName="p">{`You look out the window onto the sunny front yard and wave at your friends outside`}</inlineCode>{`.`}</p>
    </blockquote>
    <p>{`Next, we'll add a global that changes when we pull the sconce.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit the `}<Message mdxType="Message">{`PULL-SCONCE`}</Message>{` message. Drag in a `}<SetGlobalTag mdxType="SetGlobalTag" />{` tag and use it to create the new `}<Global mdxType="Global">{`PulledSconce`}</Global>{` global of type `}<inlineCode parentName="p">{`Boolean`}</inlineCode>{` (Booleans can only be true or false). Have the tag set it to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. Add some text at the end of the message text, like `}<inlineCode parentName="p">{`A door appears on the wall`}</inlineCode>{`. `}</p>
    </blockquote>
    <p>{`Finally, we'll use the post run trigger to check our new global and show the new exit if it's true.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit the `}<Location mdxType="Location">{`UPSTAIRS`}</Location>{` location. Drag an `}<IfTag mdxType="IfTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{` tag into the post run trigger area. Modify the `}<IfTag mdxType="IfTag" />{` to check if `}<Global mdxType="Global">{`PulledSconce`}</Global>{` is `}<inlineCode parentName="p">{`true`}</inlineCode>{`. Drag a `}<ShowExitTag mdxType="ShowExitTag" />{` tag in between the `}<IfTag mdxType="IfTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{` and set the location to `}<Location mdxType="Location">{`BEDROOM`}</Location>{`.  Click `}<strong parentName="p">{`Test Story`}</strong>{`. `}<br />{` `}<em parentName="p">{`VERY IMPORTANT:`}</em>{` Before you test, make sure to set `}<inlineCode parentName="p">{`PulledSconce`}</inlineCode>{` is unchecked in the form, this will make sure that it is set to false when you start the story. `}<br />{` Now when you pull the sconce, the door should appear and you can use it to wave to complete the dare.`}</p>
    </blockquote>
    <h3>{`Wrapping up`}</h3>
    <p>{`Locations open up your stories by letting the player drive the action themselves.  While feeling completely open ended to the player, you can make clever use of globals and conditionals to  still control your story's pacing and structure.`}</p>
    <p><a parentName="p" {...{
        "href": "/help"
      }}>{`Back to help`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      